import { useEffect } from 'react'
import { creationCacheAtom } from '@/atoms'
import { useSetAtom } from 'jotai'

export default function useAutoCleanCreationDetails() {
  // clear all cache data
  const setCreationDetailsMap = useSetAtom(creationCacheAtom)

  useEffect(() => {
    return () => {
      setCreationDetailsMap({})
    }
  }, [setCreationDetailsMap])
}
